#head {
  transform-origin: 0 0;
}

.contact-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  overflow: hidden;
}

.contact-container svg {
  height: 400px;
}

.contact-container .lets-chat {
  flex: 1;
  margin-bottom: 50px;
}

.contact-container .lets-chat h1 {
  color: #35a6c0;
  font-family: Lobster, cursive;
  font-size: 100px;
}

.contact-container .lets-chat p {
  font-family: Dosis, sans-serif;
  font-size: 30px;
}

.footer {
  width: 100%;
  background-color: #7bcbbc;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer div {
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 40px;
  display: flex;
}

@media (min-width: 1500px) {
  .contact-container {
    width: 80%;
    height: 100vh;
    flex-direction: row;
    align-items: flex-end;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .contact-container svg {
    flex: 1;
  }

  .footer {
    height: 260px;
  }

  .footer div {
    width: 80%;
    flex-direction: row;
    margin-left: auto;
  }
}

/*# sourceMappingURL=index.3561850f.css.map */
